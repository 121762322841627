<template>
  <component class="identity-component" :is="componentType"></component>
</template>

<script>
import {
  LazyContactUs,
  LazyForgotPasswordChallenge,
  LazyForgotPasswordVerify,
  LazyLogin,
  LazyMfaEnroll,
  LazyMfaEnrollActivate,
  LazyMfaMethods,
  LazyMfaVerify,
  LazyPasswordExpired,
  LazyResetPassword,
  LazySsnVerification,
} from '#components'
import { useIdentityStore } from '~/store/identity'
import { useRootStore } from '~/store/root'
import { useRoute } from 'vue-router'
import { componentMap } from '~/components/identity/component-map'

export default {
  name: 'Identity-Parent',
  components: {
    LazyContactUs,
    LazyForgotPasswordChallenge,
    LazyForgotPasswordVerify,
    LazyLogin,
    LazyMfaEnroll,
    LazyMfaEnrollActivate,
    LazyMfaMethods,
    LazyMfaVerify,
    LazyPasswordExpired,
    LazyResetPassword,
    LazySsnVerification,
  },
  async setup() {
    definePageMeta({
      layout: 'identity',
      middleware: async (to) => {
        if (useRootStore().isLoggedIn) {
          return navigateTo('/resume-app')
        }
        const identityStore = useIdentityStore()
        const page = to.params?.component ?? 'login';
        if (!componentMap[page]) {
          return navigateTo(identityStore.loginUrl)
        }
        return componentMap[page].middleware(to, identityStore)
      },
    })

    const page = useRoute().params?.component ?? 'login';

    return {
      componentType: componentMap[page].component,
      title: componentMap[page].title,
    }
  },
  async mounted() {
    document.title = 'Lendio - ' + this.title;
  },
}
</script>
