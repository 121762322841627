<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="passwordExpired">
      <template #title>
        Password expired.
      </template>
      <template #main>
        <div class="form-floating">
          <input
            id="oldPassword"
            name="oldPassword"
            class="form-control mx-auto mb-3"
            :type="showOldPassword ? 'text' : 'password'"
            :disabled="submitting"
            :placeholder="'Old password'"
            :required="true"
            v-model="oldPassword"
            :class="{ 'is-invalid': safeGet(v$, 'oldPassword.$invalid') && safeGet(v$, 'oldPassword.$dirty') }"
            @change="v$.oldPassword.$touch()"
            @blur="v$.oldPassword.$touch()"
            @input="v$.oldPassword.$touch()"
            @keyup.enter="validateAndSubmit"
            autocomplete="current-password"
          />
          <label for="oldPassword"><span>Old Password</span></label>
          <span class="text-tooltip-icon" @click="toggleViewPassword('showOldPassword')">
            <fa-icon v-if="showOldPassword" :icon="['fal', 'eye-slash']"></fa-icon>
            <fa-icon v-else :icon="['fal', 'eye']"></fa-icon>
          </span>
        </div>
        <div class="form-floating">
          <input
            id="password"
            name="password"
            class="form-control mx-auto"
            :type="showNewPassword ? 'text' : 'password'"
            :disabled="submitting"
            :placeholder="'New password'"
            :required="true"
            v-model="password"
            :class="{ 'is-invalid': safeGet(v$, 'password.$invalid') && safeGet(v$, 'password.$dirty') }"
            @change="v$.password.$touch()"
            @blur="v$.password.$touch()"
            @input="v$.password.$touch()"
            @keyup.enter="validateAndSubmit"
            autocomplete="new-password"
          />
          <label for="newPassword"><span>New Password</span></label>
          <span class="text-tooltip-icon" @click="toggleViewPassword('showNewPassword')">
            <fa-icon v-if="showNewPassword" :icon="['fal', 'eye-slash']"></fa-icon>
            <fa-icon v-else :icon="['fal', 'eye']"></fa-icon>
          </span>
        </div>
        <div class="ms-1 my-3">
          <div v-for="validator in passwordValidators" :key="validator">
            <div class="d-inline-block validation-icon">
              <fa-icon v-if="!safeGet(v$, `password.${validator}.$invalid`)" :icon="['far', 'check']" class="fa-check requirement-met"></fa-icon>
              <span v-else>—</span>
            </div>
            <span class="validation-message">{{ safeGet(v$, `password[${validator}.$message`) }}</span>
          </div>
        </div>
        <div class="form-floating">
          <input
            id="passwordConfirm"
            name="passwordConfirm"
            class="form-control mx-auto"
            :class="{ 'is-invalid': safeGet(v$, 'passwordConfirm.$invalid') && safeGet(v$, 'passwordConfirm.$dirty') }"
            :type="showConfirmPassword ? 'text' : 'password'"
            :disabled="submitting"
            :placeholder="'Repeat new password'"
            :required="true"
            v-model="passwordConfirm"
            @change="v$.passwordConfirm.$touch()"
            @input="v$.passwordConfirm.$touch()"
            @blur="v$.passwordConfirm.$touch()"
            @keyup.enter="validateAndSubmit"
            autocomplete="new-password"
          />
          <label for="confirmPassword"><span>Confirm Password</span></label>
          <span class="text-tooltip-icon" @click="toggleViewPassword('showConfirmPassword')">
            <fa-icon v-if="showConfirmPassword" :icon="['fal', 'eye-slash']"></fa-icon>
            <fa-icon v-else :icon="['fal', 'eye']"></fa-icon>
          </span>
        </div>
        <div class="ms-1 my-3">
          <div class="d-inline-block validation-icon">
            <fa-icon v-if="!safeGet(v$, `passwordConfirm.sameAsPassword.$invalid`)" :icon="['far', 'check']" class="fa-check requirement-met"></fa-icon>
            <span v-else>—</span>
          </div>
          <span class="validation-message">{{ safeGet(v$, `passwordConfirm.sameAsPassword.$message`) }}</span>
        </div>
      </template>
      <template #actions>
        <div class="d-flex justify-content-end">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="submitting || v$.$invalid || !v$.$dirty"
            @click="validateAndSubmit">
            <ButtonLoadingSpinner v-if="submitting"></ButtonLoadingSpinner>Reset password
          </button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import { useIdentityStore } from '~/store/identity'
import { useVuelidate } from '@vuelidate/core'
import ButtonLoadingSpinner from '../marketplace/subComponents/ButtonLoadingSpinner'
import Card from './Card.vue'
import get from 'lodash/get'

export default {
  name: 'IdentityPasswordExpired',
  components: [
    ButtonLoadingSpinner,
    Card,
  ],
  async setup() {
    return {
      v$: useVuelidate(),
      safeGet: get,
      identityStore: useIdentityStore(),
    }
  },
  data() {
    return {
      password: '',
      oldPassword: '',
      passwordConfirm: '',
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      submitting: false,
      passwordValidators: [
        'minLength',
        'minLowercase',
        'minUppercase',
        'minNumber',
      ]
    }
  },
  validations() {
    return {
      oldPassword: {
        required,
      },
      password: {
        required,
        minLength: { $validator: (value) => value.length >= 8, $message: 'At least 8 characters long' },
        minLowercase: { $validator: (value) => value.match(/[a-z]/), $message: 'A lowercase letter' },
        minUppercase: { $validator: (value) => value.match(/[A-Z]/), $message: 'An uppercase letter' },
        minNumber: { $validator: (value) => value.match(/[0-9]/), $message: 'A number' },
      },
      passwordConfirm: {
        required,
        sameAsPassword: { $validator: (value) => value !== '' && value === this.password, $message: 'Password confirmed' },
      },
    }
  },
  methods: {
    async validateAndSubmit() {
      this.v$?.$touch()
      if (this.v$?.$invalid) return
      this.submitting = true
      try {
        await this.identityStore.resetExpiredPassword(this.oldPassword, this.password, this.passwordConfirm);
      } catch (error) {
        this.submitting = false
        this.v$.$invalid = true;
        const errors = get(error, 'response.data.errors') ?? ['Failed to reset password. Please try again.']
        const errorMessage = errors.join(', ')
        this.showErrorAlert(errorMessage)
      }
    },
    showErrorAlert (message) {
      if (this.$refs?.passwordExpired?.$refs?.alert) {
        this.$refs.passwordExpired.$refs.alert.openDanger({
          content: message,
          untrusted: true
        })
      }
    },
    toggleViewPassword(varName) {
      this[varName] = !this[varName]
    },
  }
}
</script>

<style lang="scss" scoped>
.validation-icon {
  margin-right: 12px;
}
.validation-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #777170;
}
.requirement-met {
  color: #68B79E;
}
</style>
