<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="mfaOptions">
      <template #title>
        {{ title }}
      </template>
      <template #main>
        <p class="mb-6">Select your preferred multi-factor authentication method to continue.</p>
        <div v-if="identityStore.mfaMethods.length">
          <div v-for="method in identityStore.mfaMethods" :key="method">
            <button
              :class="`${method}-mfa-select-btn btn btn-primary w-100 mb-3 text-center d-flex align-items-center justify-content-center`"
              @click="submitMfaMethod(method)"
              :disabled="submitting">
              <span class="align-middle">
                <ButtonLoadingSpinner v-if="submitting && selectedMfaMethod === method" class="me-2" />
                <fa-icon class="fa-xl me-2" :icon="['fal', methodMap[method].icon]"></fa-icon>
                {{ methodMap[method].name }}
              </span>
            </button>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import {useIdentityStore} from "~/store/identity";
import Card from "~/components/identity/Card.vue";

export default {
  name: 'IdentityMfaMethods',
  components: {
    Card,
  },
  async setup() {
    return {
      identityStore: useIdentityStore(),
    }
  },
  data() {
    return {
      submitting: false,
      selectedMfaMethod: null,
      title: this.identityStore.enrolling ? 'Let\'s make your account more secure.' : 'Verify your account.',
      methodMap: {
        sms: {
          icon: 'message',
          name: 'Text message',
        },
        totp: {
          icon: 'key',
          name: 'Authenticator app',
        },
      },
    }
  },
  methods: {
    async submitMfaMethod(method) {
      this.submitting = true
      this.selectedMfaMethod = method
      await this.identityStore.selectMfaMethod(method)
      this.submitting = false
    },
  }
}
</script>
