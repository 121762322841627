<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="enroll">
      <template #title>
        Let's make your account more secure.
      </template>
      <template #main>
        <div v-if="sms">
          <p>Enter your phone number below. We'll text you a six digit code to verify your account.</p>
          <div class="form-floating">
            <input
              id="phone"
              name="phone"
              class="form-control mx-auto"
              type="tel"
              :disabled="submitting"
              :placeholder="'Enter mobile phone number'"
              :required="true"
              v-model="phone"
              v-maska data-maska="(###) ###-####"
              :class="{ 'is-invalid': safeGet(v$, 'phone.$invalid') && safeGet(v$, 'phone.$dirty') }"
              @change="v$.phone.$touch()"
              @blur="v$.phone.$touch()"
              @input="v$.phone.$touch()"
              @keyup.enter="validateAndSubmit()"
              inputmode="tel"
              autocomplete="tel-national"
            />
            <span class="invalid-feedback" v-if="safeGet(v$, 'phone.$error')">
              <fa-icon :icon="['fal', 'circle-exclamation']"></fa-icon>
              {{ v$.phone.$errors[0].$message }}
            </span>
            <label for="phone"><span>Enter mobile phone number</span></label>
          </div>
        </div>
        <div v-if="totp">
          <p>Scan the QR code below with your authenticator app.</p>
          <div class="d-flex justify-content-center w-full mb-2">
            <img :src="identityStore.totpMfaQrCode" alt="MFA Enrollment QR code" />
          </div>
          <div class="mb-6 text-center w-full">
            Can't scan? <a href="javascript:;" @click="copyTotpSeed()">Copy the key.</a>
          </div>
        </div>
      </template>
      <template #actions>
        <div :class="`d-flex ${identityStore.mfaMethods.length > 1 ? 'justify-content-between' : 'justify-content-end'}`">
          <BackButton v-if="identityStore.mfaMethods.length > 1" :to="identityStore.mfaMethodsUrl"></BackButton>
          <button
            type="submit"
            v-if="sms"
            class="btn btn-primary"
            :disabled="submitting || v$.$invalid || !v$.$dirty"
            @click="validateAndSubmit()">
            <ButtonLoadingSpinner v-if="submitting"></ButtonLoadingSpinner>Send text
          </button>
          <button
            v-if="totp"
            class="btn btn-primary"
            :disabled="submitting"
            @click="validateAndSubmit()">
            <ButtonLoadingSpinner v-if="submitting"></ButtonLoadingSpinner>Continue
          </button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { required, minLength } from '@vuelidate/validators'
import ButtonLoadingSpinner from '../marketplace/subComponents/ButtonLoadingSpinner'
import get from 'lodash/get'
import useVuelidate from '@vuelidate/core';
import { useIdentityStore } from '~/store/identity';
import { helpers } from '@vuelidate/validators'
const { withMessage } = helpers

export default {
  name: 'IdentityMfaEnroll',
  components: [
    ButtonLoadingSpinner,
  ],
  async setup() {
    return {
      v$: useVuelidate(),
      safeGet: get,
      identityStore: useIdentityStore(),
    };
  },
  data() {
    return {
      phone: '',
      submitting: false,
    };
  },
  validations() {
    return {
      phone: {
        required: required,
        minLength: withMessage('Please enter a valid phone number', minLength(14)),
      },
    };
  },
  methods: {
    async copyTotpSeed() {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        try {
          await navigator.clipboard.writeText(this.identityStore.totpMfaSeed);
          this.$refs.enroll.$refs.alert.openSuccess({
            content: 'Key copied to clipboard',
            untrusted: true
          });
        } catch (err) {
          this.$refs.enroll.$refs.alert.openDanger({
            content: 'Failed to copy key. Please try again.',
            untrusted: true
          });
          console.error('Failed to copy TOTP seed: ', err);
        }
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = this.identityStore.totpMfaSeed;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          this.$refs.enroll.$refs.alert.openSuccess({
            content: 'Key copied to clipboard',
            untrusted: true
          });
        } catch (err) {
          this.$refs.enroll.$refs.alert.openDanger({
            content: 'Failed to copy key. Please try again.',
            untrusted: true
          });
          console.error('Failed to copy TOTP seed: ', err);
        }
        document.body.removeChild(textArea);
      }
    },
    async validateAndSubmit() {
      switch (this.identityStore.selectedMfaMethod) {
        case 'sms':
          this.v$.phone.$touch();
          if (!this.v$.phone?.$invalid) {
            await this.enroll()
          }
          break;
        case 'totp':
          await navigateTo(this.identityStore.mfaEnrollActivateUrl)
          break;
      }
    },
    async enroll() {
      this.submitting = true;
      try {
        const formattedPhone = '+1' + this.phone.replace(/[^\d]/g, '');
        await this.identityStore.mfaEnroll('sms', formattedPhone);
      } catch (error) {
        if (this.$refs?.enroll?.$refs?.alert) {
          this.$refs.enroll.$refs.alert.openDanger({
            content: error.message ?? 'Failed to send MFA challenge. Please try again.',
            untrusted: true
          })
        }
        this.submitting = false
      }
    },
  },
  computed: {
    sms() {
      return this.identityStore.selectedMfaMethod === 'sms'
    },
    totp() {
      return this.identityStore.selectedMfaMethod === 'totp'
    },
  }
}
</script>
