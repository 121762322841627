<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="mfaVerify">
      <template #title>
        Verify your account.
      </template>
      <template #main>
        <div class="mb-6">
          Enter the 6 digit code {{ maskedMfaDestination }}.
        </div>
        <MfaCodeInput
          @submit="verify"
          @resend="resendCode"
          :dontChallengeOption="true"
          :submitting="submitting"
        ></MfaCodeInput>
      </template>
    </Card>
  </div>
</template>

<script>
import {useIdentityStore} from "~/store/identity";
import Card from "~/components/identity/Card.vue";
import MfaCodeInput from "./MfaCodeInput.vue";

export default {
  name: 'IdentityMfaVerify',
  components: {
    Card,
    MfaCodeInput,
  },
  async setup() {
    return {
      identityStore: useIdentityStore(),
    }
  },
  data() {
    return {
      submitting: false,
      maskedMfaDestination: '',
    }
  },
  beforeMount() {
    switch (this.identityStore.selectedMfaMethod) {
      case 'sms':
        this.maskedMfaDestination = `sent to ${this.identityStore.maskedMfaDestination}`
        break
      case 'totp':
        this.maskedMfaDestination = 'from your authenticator app'
        break
    }
  },
  methods: {
    async verify(value) {
      try {
        this.submitting = true
        await this.identityStore.mfaVerify(this.identityStore.selectedMfaMethod, value.code, value.rememberDevice)
      } catch (error) {
        this.showErrorAlert(error.message ?? 'Failed to verify code. Please try again.')
        this.submitting = false
      }
    },
    async resendCode() {
      try {
        await this.identityStore.mfaChallenge(this.identityStore.selectedMfaMethod, true)
      } catch (error) {
        this.showErrorAlert(error.message ?? 'Failed to resend code. Please try again.')
      }
    },
    showErrorAlert(message) {
      if (this.$refs?.mfaVerify?.$refs?.alert) {
        this.$refs.mfaVerify.$refs.alert.openDanger({
          content: message,
          untrusted: true
        })
      }
    },
  }
}
</script>
